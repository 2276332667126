import React from 'react';
import styled from 'styled-components';

import Layout from '@common/Layout';
import Navbar from '@common/Navbar';
import { PageSEO } from '@common/SEO';

import {
  Section,
  Container,
  // DesignText,
  TechnologyText,
  // RunningText,
} from '@components/global';

import { Content, PageTitle, /*Title,*/ Paragraph } from '@components/pages';

import Footer from '@sections/Footer';

const quotes = [
  '“Go somewhere you know nothing about and see what happens.”',
  '“Sometimes it takes a wrong turn to get you to the right place.”',
  '“Your grandma always had a terrible sense of direction. She could get lost on an escalator.”',
];

const title = 'Not found';
const description = "There's nothing here, please go back";

const NotFoundPage = () => (
  <Layout>
    <PageSEO title={title} description={description} />
    <Navbar page />

    <Wrapper>
      <Section>
        <Container>
          <Content>
            <PageTitle>
              <TechnologyText>
                {quotes[Math.floor(Math.random() * quotes.length)]}
              </TechnologyText>
            </PageTitle>
            <Paragraph>There's nothing here, please go back.</Paragraph>
          </Content>
        </Container>
      </Section>

      <Footer neutral />
    </Wrapper>
  </Layout>
);

export default NotFoundPage;

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  min-height: 100vh;
`;
